import React from "react"
import Container from "./container"
import heroSectionStyles from "./hero_section.module.scss"
// import containerStyles from "./container.module.scss"

export default function HeroSection({ children }) {
  return (
    <section id={heroSectionStyles.hero}>
      <Container>
        {children}
      </Container>
    </section>
    )
}
