import React from "react"
import Layout from "../components/layout"
import HeroSection from "../components/hero_section"
import linkStyles from "../components/links.module.scss"

export default function About({ data }) {
  return (
    <Layout>
      <HeroSection>

      <h1>You got lost</h1>
      <p>
        Click <a className={[linkStyles.links, linkStyles.linkTomato].join(' ')} href="/">here</a> to find your way back.
      </p>

      </HeroSection>
    </Layout>
  )
}